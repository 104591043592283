import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ADMIN } from '../const';
import { AdminAuthService } from '../services/auth.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {
    constructor(private authService: AdminAuthService) {}

    canActivate(): boolean {
        if (this.authService.authenticated) {
            return true;
        }

        const searchParams = new URLSearchParams(location.search);
        if (!searchParams.has('redirectTo')) {
            let redirectTo = location.pathname || '';
            if (redirectTo.indexOf('/password') !== -1) {
                redirectTo = ADMIN.URLS.HOME;
            }
            this.authService.login(redirectTo);
        }

        return false;
    }
}
